import useMediaQuery from "./hooks/useDimensions";

const SMALL_FONT_SIZE = 10;
const GREY_COLOR = "hsl(0, 0%, 90%)";

const STROKE_WIDTH = 4;

export const Graph = ({
  graph,
  options,
  colorMap,
  onNodeClick,
  selectedNode,
  selectedEdges,
  edgeEndNodes,
  baseFontSize,
  nodeDimensions,
  hideMatriLabels,
  language,
}) => {
  const { isMobile } = useMediaQuery();
  const nodeRadius = nodeDimensions.height / 2;
  const cornerNodeRadius = nodeDimensions.width * (1 / 3);

  // Make sure the selected edges are always on top
  const sortedEdges = graph.edges.sort((a, b) => {
    const isSelectedEdgeA = selectedEdges.some(
      (selectedEdge) => selectedEdge.id === a.id
    );
    const isSelectedEdgeB = selectedEdges.some(
      (selectedEdge) => selectedEdge.id === b.id
    );
    if (isSelectedEdgeA && !isSelectedEdgeB) {
      return 1;
    }
    if (!isSelectedEdgeA && isSelectedEdgeB) {
      return -1;
    }
    return 0;
  });

  // Use SVG instead of third party library
  return (
    <div>
      <svg
        height={options.height}
        width={options.width}
        viewBox={`0 0 ${options.width} ${options.height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {sortedEdges.map((edge, i) => {
          const from = graph.nodes.find((node) => node.id === edge.from);
          const to = graph.nodes.find((node) => node.id === edge.to);
          const color = colorMap[edge.title];
          const isSelectedEdge = selectedEdges.some(
            (selectedEdge) => selectedEdge.id === edge.id
          );
          const shouldGreyOut = selectedEdges.length > 0 && !isSelectedEdge;

          // If path is a curve
          // We read edge.curvePoints to get the points of the curve
          const curvePoint = edge.curvePoint;
          if (curvePoint) {
            const curvePointX = curvePoint.x;
            const curvePointY = curvePoint.y;
            const leftCurve = curvePointX < from.x;

            return (
              <path
                key={`curve-${edge.id}-${i}`}
                d={`M ${from.x} ${
                  from.y
                } C ${curvePointX} ${curvePointY} ${curvePointX} ${curvePointY} ${
                  leftCurve ? to.x - cornerNodeRadius : to.x + cornerNodeRadius
                } ${to.y + cornerNodeRadius}`}
                stroke={shouldGreyOut ? GREY_COLOR : color}
                strokeWidth={STROKE_WIDTH}
                fill="none"
              />
            );
          }

          return (
            <path
              key={`line-${edge.id}`}
              d={`M ${from.x} ${from.y} L ${to.x} ${to.y}`}
              stroke={shouldGreyOut ? GREY_COLOR : color}
              strokeWidth={STROKE_WIDTH}
              fill="none"
            />
          );
        })}
        {/* Add arrows to the edges to indicate direction */}
        {/* This is only for mother -> child relations */}
        {graph.edges
          .filter((edge) => edge.title === "mother of")
          .map((edge, i) => {
            const to = graph.nodes.find((node) => node.id === edge.to);
            const color = colorMap[edge.title];
            const isSelectedEdge = selectedEdges.some(
              (selectedEdge) => selectedEdge.id === edge.id
            );
            const shouldGreyOut = selectedEdges.length > 0 && !isSelectedEdge;

            // Arrow head, pointing down

            const yEnd = to.y - nodeRadius - 1;
            const arrowSize = 10;
            const largeArrowSize = 15;
            return (
              <g key={`arrow-${edge.id}`}>
                {!edge.curvePoint && (
                  // Arrow head, pointing down
                  <path
                    d={`M ${to.x - arrowSize} ${yEnd - arrowSize} L ${
                      to.x
                    } ${yEnd} L ${to.x + arrowSize} ${yEnd - arrowSize}`}
                    stroke={shouldGreyOut ? GREY_COLOR : color}
                    strokeWidth={STROKE_WIDTH}
                    fill="none"
                  />
                )}
                {edge.curvePoint && edge.arrowDirection === "right" && (
                  // Arrow head, angled up at 45 degrees
                  <path
                    key={`arrow-${edge.id}`}
                    d={`M ${to.x - cornerNodeRadius - largeArrowSize} ${
                      to.y + cornerNodeRadius
                    } L ${to.x - cornerNodeRadius} ${to.y + cornerNodeRadius}
                      L ${to.x - cornerNodeRadius} ${
                      to.y + cornerNodeRadius + largeArrowSize
                    }`}
                    stroke={shouldGreyOut ? GREY_COLOR : color}
                    strokeWidth={STROKE_WIDTH}
                    fill="none"
                  />
                )}
                {edge.curvePoint && edge.arrowDirection === "left" && (
                  // Arrow head, angled up at 45 degrees
                  <path
                    key={`arrow-${edge.id}`}
                    d={`M ${to.x + cornerNodeRadius + largeArrowSize} ${
                      to.y + cornerNodeRadius
                    } L ${to.x + cornerNodeRadius} ${to.y + cornerNodeRadius}
                      L ${to.x + cornerNodeRadius} ${
                      to.y + cornerNodeRadius + largeArrowSize
                    }`}
                    stroke={shouldGreyOut ? GREY_COLOR : color}
                    strokeWidth={STROKE_WIDTH}
                    fill="none"
                  />
                )}
              </g>
            );
          })}

        {graph.nodes.map((node, i) => {
          const color = colorMap[node.title];
          const isSelectedNode = selectedNode && selectedNode.id === node.id;
          const edgeEndNode = edgeEndNodes.find(
            (edgeEndNode) => edgeEndNode.id === node.id
          );
          const shouldGreyOut = selectedNode && !isSelectedNode && !edgeEndNode;
          const offsetForSubheading =
            edgeEndNode || isSelectedNode ? SMALL_FONT_SIZE / 2 : 0;

          // Sublabel is the text that appears below the skin names
          let subLabel = null;
          if (edgeEndNode) {
            subLabel = edgeEndNode.relation;
            if (edgeEndNode.relation === "children") {
              subLabel = "woman's children";
            }
          } else if (isSelectedNode) {
            subLabel = isMobile ? "me & siblings" : "me & my siblings";
          }

          const isMotherNode = graph.edges.find(
            (edge) =>
              edge.from === node.id &&
              edge.title === "mother of" &&
              selectedNode &&
              edge.to === selectedNode.id
          );

          // Audio
          const audioWidth = 106;
          const audioHeight = 16;

          // Y values

          // Masculine
          const labelMascY = node.y - baseFontSize - offsetForSubheading;
          const audioMascY = labelMascY;

          // Feminine
          const labelFemY = node.y + baseFontSize - offsetForSubheading;
          const audioFemY = labelFemY;

          // Sublabel
          const subLabelY = labelFemY + baseFontSize + SMALL_FONT_SIZE;

          // Content values
          const mascLabel = node.labelMasc[language];
          const femLabel = node.labelFem[language];

          // Audio
          const hasFemAudio = Boolean(femLabel.audio);
          const hasMascAudio = Boolean(mascLabel.audio);
          const mascLabelId = `audio-masc-${node.id}-${language}`;
          const femLabelId = `audio-fem-${node.id}-${language}`;

          // For each node, draw an ellipse
          // and add a label
          return (
            <g
              key={node.id}
              onClick={(e) => {
                onNodeClick(node);
                e.stopPropagation();
              }}
              style={{ cursor: "pointer" }}
            >
              {/* If the node is selected, draw a border around it */}
              <ellipse
                cx={node.x}
                cy={node.y}
                rx={node.width / 2}
                ry={node.height / 2}
                fill={shouldGreyOut ? GREY_COLOR : color}
                strokeWidth={isSelectedNode ? 5 : 0}
                stroke={adjustLightness(color, -20)}
              />
              {/* MASCULINE LABEL */}
              <text
                x={node.x}
                y={labelMascY}
                textAnchor="middle"
                alignmentBaseline="middle"
                fill="black"
                fontSize={baseFontSize}
                fontWeight={
                  isSelectedNode || (edgeEndNode && !isMotherNode)
                    ? "bold"
                    : "normal"
                }
                onClick={(e) => {
                  document.getElementById(mascLabelId).play();
                  e.stopPropagation();
                }}
              >
                {`♂ ${mascLabel.label}${hasMascAudio ? " ▶️" : ""}`}
              </text>
              {/* MASCULINE AUDIO */}
              <foreignObject
                key={`foreign-object-${mascLabelId}`}
                x={node.x - audioWidth / 2}
                y={audioMascY}
                width={audioWidth}
                height={audioHeight}
              >
                {/* Audio */}
                <audio
                  id={mascLabelId}
                  style={{
                    width: `${audioWidth}px`,
                    height: `${audioHeight}px`,
                  }}
                >
                  <source
                    src={`${process.env.REACT_APP_SKINS_PUBLIC_URL}${mascLabel.audio}`}
                    type="audio/mpeg"
                  />
                  Your browser does not support the audio element.
                </audio>
              </foreignObject>
              {/* FEMININE LABEL */}
              <text
                x={node.x}
                y={labelFemY}
                textAnchor="middle"
                alignmentBaseline="middle"
                fill="black"
                fontSize={baseFontSize}
                fontWeight={isSelectedNode || edgeEndNode ? "bold" : "normal"}
                onClick={(e) => {
                  document.getElementById(femLabelId).play();
                  e.stopPropagation();
                }}
              >
                {`♀ ${femLabel.label}${hasFemAudio ? " ▶️" : ""}`}
              </text>
              {/* FEMININE AUDIO */}
              <foreignObject
                key={`foreign-object-${femLabelId}`}
                x={node.x - audioWidth / 2}
                y={audioFemY}
                width={audioWidth}
                height={audioHeight}
              >
                {/* Audio */}
                <audio
                  id={femLabelId}
                  style={{
                    width: `${audioWidth}px`,
                    height: `${audioHeight}px`,
                  }}
                >
                  <source
                    src={`${process.env.REACT_APP_SKINS_PUBLIC_URL}${femLabel.audio}`}
                    type="audio/mpeg"
                  />
                  Your browser does not support the audio element.
                </audio>
              </foreignObject>

              {subLabel && (
                <text
                  x={node.x}
                  y={subLabelY}
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  fill="black"
                  fontSize={SMALL_FONT_SIZE}
                  textDecoration={"underline"}
                >
                  {subLabel}
                </text>
              )}
            </g>
          );
        })}
        {/* LABELS */}
        {!hideMatriLabels &&
          graph.labels.map((label, i) => {
            const color = colorMap["text"];
            const matrimoietyLabel =
              graph.matrimoieties[label.text].languages[language];
            return (
              <text
                key={`label-${i}`}
                x={label.x}
                y={label.y}
                width={label.width}
                height={label.height}
                fill={color}
                fontSize={label.fontSize}
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {matrimoietyLabel}
              </text>
            );
          })}
      </svg>
    </div>
  );
};

// Take an HSL color string and return a new HSL color string with the lightness adjusted by the given amount
function adjustLightness(hsl, amount) {
  const hslRegex = /hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/;
  const [, h, s, l] = hsl.match(hslRegex);
  return `hsl(${h}, ${s}%, ${parseInt(l) + amount}%)`;
}
