import React, { useEffect, useState } from "react";
import { Graph } from "./Graph";
import { LegendLine, LegendCircle } from "./Shapes";

// import "./styles.css";
// need to import the vis network css in order to show tooltip
// import "./network.css";

export function SkinsGraph({
  graph,
  options,
  colorMap,
  baseFontSize,
  nodeDimensions,
  hideMatriLabels,
  language,
  hideExplainerSentences,
}) {
  const [sentences, setSentences] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdges, setSelectedEdges] = useState([]);
  const [edgeEndNodes, setEdgeEndNodes] = useState([]);

  const { edges: graphEdges } = graph;

  const nodesKeyed = keyBy(graph.nodes, "id");
  //   const edgesKeyed = keyBy(graph.edges, "id");

  useEffect(
    () => {
      let sentences = [];
      let selectedEdges = [];
      let edgeEndNodes = [];

      if (!selectedNode) {
        setSentences([]);
        setSelectedEdges([]);
        setEdgeEndNodes([]);
        return;
      } else {
        ////////////// SIBLINGS //////////////
        sentences.push({
          sentence: `${selectedNode.labelMasc[language].label} is male and ${selectedNode.labelFem[language].label} is female.`,
          type: selectedNode.title,
        });
        sentences.push({
          sentence: `They are both ${selectedNode.title} moiety`,
          type: selectedNode.title,
        });

        ////////////// MOTHER //////////////
        // Get mother of selected node
        const motherEdge = graphEdges.find((edge) => {
          return edge.to === selectedNode.id && edge.title === "mother of";
        });
        selectedEdges.push(motherEdge);

        const motherNode = nodesKeyed[motherEdge.from];
        edgeEndNodes.push({ ...motherNode, relation: "mother" });

        sentences.push({
          sentence: `Their mother is ${
            nodesKeyed[motherEdge.from].labelFem[language].label
          }`,
          type: motherEdge.title,
        });

        ////////////// CHILDREN //////////////
        // Get children of selected node
        const childEdge = graphEdges.find((edge) => {
          return edge.from === selectedNode.id && edge.title === "mother of";
        });
        selectedEdges.push(childEdge);

        const childNode = nodesKeyed[childEdge.to];
        edgeEndNodes.push({ ...childNode, relation: "children" });

        sentences.push({
          sentence: `The children of ${
            selectedNode.labelFem[language].label
          } will get the skins ${
            nodesKeyed[childEdge.to].labelMasc[language].label
          } and ${nodesKeyed[childEdge.to].labelFem[language].label}`,
          type: childEdge.title,
        });

        ////////////// MARRIAGE //////////////

        // Get marriage choices of selected node
        const marriageEdges = graphEdges.filter((edge) => {
          return edge.from === selectedNode.id && edge.title === "should marry";
        });
        selectedEdges.push(...marriageEdges);

        // Get marriage choices of selected node
        const marriageEdgeEndNodes = marriageEdges.map((edge) => {
          const marryNode = nodesKeyed[edge.to];
          return { ...marryNode, relation: "potential partner" };
        });
        edgeEndNodes.push(...marriageEdgeEndNodes);

        // Push marriage choices for selected node
        sentences.push({
          sentence: `${selectedNode.labelMasc[language].label} / ${
            selectedNode.labelFem[language].label
          } should marry ${marriageEdges
            .map((edge) => {
              return (
                nodesKeyed[edge.to].labelMasc[language].label +
                " / " +
                nodesKeyed[edge.to].labelFem[language].label
              );
            })
            .join(", or ")}`,
          type: marriageEdges[0].title,
        });

        // Set the sentences in the state
      }
      setSentences(sentences);
      setSelectedEdges(selectedEdges);
      setEdgeEndNodes(edgeEndNodes);
    },
    [selectedNode] // eslint-disable-line
  );

  return (
    <div
      onClick={() => {
        setSelectedNode(null);
      }}
    >
      <Graph
        graph={graph}
        options={options}
        onNodeClick={(node) => {
          setSelectedNode(node);
        }}
        colorMap={colorMap}
        selectedNode={selectedNode}
        selectedEdges={selectedEdges}
        edgeEndNodes={edgeEndNodes}
        baseFontSize={baseFontSize}
        nodeDimensions={nodeDimensions}
        hideMatriLabels={hideMatriLabels}
        language={language}
      />

      <div>
        {!hideExplainerSentences &&
          sentences.map((sentence, i) => (
            <div key={i} style={{ marginBottom: 5 }}>
              <div
                style={{
                  display: "inline-flex",
                  height: baseFontSize,
                }}
              >
                {sentence.type === "Duwa" || sentence.type === "Yirridjdja" ? (
                  <LegendCircle
                    height={baseFontSize}
                    width={baseFontSize}
                    color={colorMap[sentence.type]}
                  ></LegendCircle>
                ) : (
                  <LegendLine
                    height={baseFontSize}
                    width={baseFontSize}
                    color={colorMap[sentence.type]}
                  ></LegendLine>
                )}
              </div>
              <span
                style={{
                  display: "inline",
                  marginLeft: 5,
                }}
              >
                {sentence.sentence}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
}

function keyBy(arr, key) {
  return arr.reduce((acc, x) => {
    acc[x[key]] = x;
    return acc;
  }, {});
}
