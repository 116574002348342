import "./App.css";
import React, { useRef, useState } from "react";
import { SkinsGraph } from "./SkinsGraph";
import { LegendCircle, LegendLine } from "./Shapes";
import useComponentSize from "@rehooks/component-size";
import useMediaQuery from "./hooks/useDimensions";
import {
  DUWA_COLOR,
  MARRIAGE_1_COLOR,
  MARRIAGE_2_COLOR,
  MOTHER_CHILD_COLOR,
  PADDING,
  YIRRIDJDJA_COLOR,
} from "./config/constants";

function App({
  hideMatriLabels = false,
  hideExplainerSentences = true,
  defaultLanguage = "W",
  languageOptions = ["W", "Kdj", "I", "E", "KB", "BG", "NDj", "Dj", "Mg", "Gg"],
}) {
  // State
  const [language, setLanguage] = useState(defaultLanguage);

  // Keep track of width of vis
  let visRef = useRef(null);
  let { width: fullGraphWidth } = useComponentSize(visRef);
  const { isMobile } = useMediaQuery();

  const graphWidth = fullGraphWidth - PADDING * 2;
  const graphHeight = isMobile ? graphWidth * 1.5 : graphWidth;

  const graphFontSize = isMobile ? 12 : 14;
  const headingFontSize = isMobile ? 16 : 22;

  const graphCenterX = graphWidth / 2;
  const graphCenterY = graphHeight / 2 + 30;

  const RECT_WIDTH = isMobile ? graphWidth * (3 / 7) : graphWidth * (1 / 2);
  const RECT_HEIGHT = isMobile ? graphHeight * (2 / 9) : graphHeight * (6 / 25);
  const GRAPH_START_Y = graphCenterY - RECT_HEIGHT * (3 / 2);
  const NGARRADJKU_X = graphCenterX - RECT_WIDTH / 2;
  const MARDKU_X = graphCenterX + RECT_WIDTH / 2;

  const colorMap = {
    Duwa: DUWA_COLOR,
    Yirridjdja: YIRRIDJDJA_COLOR,
    "mother of": MOTHER_CHILD_COLOR,
    "should marry": MARRIAGE_1_COLOR,
    text: "black",
  };

  const font = {
    size: 18,
  };

  const INSET = 0;
  const curveWidth = isMobile ? graphWidth * (11 / 32) : graphWidth * (5 / 16);

  const nodeDimensions = {
    width: isMobile ? 140 : 190,
    height: isMobile ? 80 : 130,
  };

  // Gurr-goni (Gg) skin names (Similar to BG)
  // Balang
  // Belinj
  // Bangardi
  // Bangardidjan
  // Gela
  // Galidjan
  // Godjok
  // Gotjan
  // Ngarridj
  // Ngarritjan
  // Gamarrang
  // Gamanj
  // Burlanj
  // Burlandjan
  // Wamut
  // Wamutjan

  // Gurr-goni sound files
  // GG_balang_LY.mp3
  // GG_belinj_LY.mp3
  // GG-bangardi_MayM.mp3
  // GG_bangardidjan_MayM.mp3
  // GG_gela_LY.mp3
  // GG_galidjan_MayM.mp3
  // GG_godjok_LY.mp3
  // GG_gotjan_MayM.mp3
  // GG_ngarridj_LY.mp3
  // GG_ngarritjan_LY.mp3
  // GG_gamarrang_LY.mp3
  // GG_gamanj_MayM.mp3
  // GG_burlanj_MayM.mp3
  // GG_burlanjdjan_LY.mp3
  // GG_wamut_MayM.mp3
  // GG_wamutjan_LY.mp3

  // GG moiety names
  // Djowunga and Yirrtjinga

  const graph = {
    languages: {
      W: "Kunwinjku",
      Kdj: "Kundjeyhmi",
      I: "Kuninjku",
      E: "Kune",
      BG: "Burarra & Gun-nartpa",
      NDj: "Ndjébbana",
      KB: "Kunbarlang",
      Dj: "Wurlaki & Djinang",
      Mg: "Mawng",
      Gg: "Gurr-goni",
    },
    moieties: {
      Duwa: {
        color: DUWA_COLOR,
        languages: {
          W: "Duwa",
          Kdj: "Duwa",
          I: "Duwa",
          E: "Duwa",
          BG: "Jowunga",
          NDj: "Djówanga",
          KB: "Kunbarlang",
          Dj: "Djowinge",
          Mg: "Duwa",
          Gg: "Djowunga",
        },
      },
      Yirridjdja: {
        color: YIRRIDJDJA_COLOR,
        languages: {
          W: "Yirridjdja",
          Kdj: "Yirridjdja",
          I: "Yirridjdja",
          E: "Yirridjdja",
          BG: "Yirrchinga",
          NDj: "Yírridjanga",
          KB: "Yirridjdja",
          Dj: "Yirritjing",
          Mg: "Irritja",
          Gg: "Yirrtjinga",
        },
      },
    },
    matrimoieties: {
      Ngarradjku: {
        languages: {
          W: "Ngarradjku",
          Kdj: "Ngarradjku",
          I: "Ngarradjku",
          E: "Ngarradjku",
          BG: "",
          NDj: "Ngarradjku",
          KB: "Ngarradjku",
          Dj: "",
          Mg: "Ngarrangarrajku",
        },
      },
      Mardku: {
        languages: {
          W: "Mardku",
          Kdj: "Mardku",
          I: "Mardku",
          E: "Mardku",
          BG: "",
          NDj: "Mardku",
          KB: "Mardku",
          Dj: "",
          Mg: "Martkumartku",
        },
      },
    },
    labels: [
      {
        text: "Ngarradjku",
        x: NGARRADJKU_X,
        y: GRAPH_START_Y - 90,
        fontSize: headingFontSize,
      },
      {
        text: "Mardku",
        x: MARDKU_X,
        y: GRAPH_START_Y - 90,
        fontSize: headingFontSize,
      },
    ],
    nodes: [
      {
        id: "xngarridj",
        labelMasc: {
          W: {
            label: "Na-ngarridj",
            audio: "audio/nangarridj.mp3",
          },
          Mg: {
            label: "Nangarrij",
          },
          KB: {
            label: "Na-ngarridj",
            audio: "audio/nangarridj.mp3",
          },
          Kdj: {
            label: "Na-ngarridj",
            audio: "audio/nangarridj.mp3",
          },
          I: {
            label: "Balang",
          },
          E: {
            label: "Balang",
          },
          BG: {
            label: "Balang",
            audio: "audio/UO_balang_01.mp3",
          },
          NDj: {
            label: "Ngárridj",
          },
          Dj: {
            label: "Balang",
          },
          Gg: {
            label: "Balang",
            audio: "audio/GG_balang_LY.mp3",
          },
        },
        labelFem: {
          W: {
            label: "Ngal-ngarridj",
            audio: "audio/ngalngarridj.mp3",
          },
          Mg: {
            label: "Ngalngarrij",
          },
          KB: {
            label: "Ngal-ngarridj",
            audio: "audio/ngalngarridj.mp3",
          },
          Kdj: {
            label: "Al-ngarridj",
            audio: "audio/alngarridj.mp3",
          },
          I: {
            label: "Belinj",
            audio: "audio/belinj.mp3",
          },
          E: {
            label: "Belinj",
            audio: "audio/belinj.mp3",
          },
          BG: {
            label: "Beleny / Belenyjan",
            audio: "audio/UO_beleny_01.mp3",
          },
          NDj: {
            label: "Njangárridj",
          },
          Dj: {
            label: "Bilindjan",
          },
          Gg: {
            label: "Belinj",
            audio: "audio/GG_belinj_LY.mp3",
          },
        },
        x: NGARRADJKU_X,
        y: GRAPH_START_Y,
        color: DUWA_COLOR,
        font: font,
        title: "Duwa",
        ...nodeDimensions,
      },
      {
        id: "xkamarrang",
        labelMasc: {
          W: {
            label: "Na-kamarrang",
            audio: "audio/nakamarrang.mp3",
          },
          Mg: {
            label: "Namarrang",
          },
          KB: {
            label: "Na-kamarrang",
            audio: "audio/nakamarrang.mp3",
          },
          Kdj: {
            label: "Na-kamarrang",
            audio: "audio/nakamarrang.mp3",
          },
          I: {
            label: "Bangardi",
            audio: "audio/bangardi.mp3",
          },
          E: {
            label: "Bangardi",
            audio: "audio/bangardi.mp3",
          },
          BG: {
            label: "Bangardi",
            audio: "audio/UO_bangardi_01.mp3",
          },
          NDj: {
            label: "Nkámarrang",
          },
          Dj: {
            label: "Bangadi",
          },
          Gg: {
            label: "Bangardi",
            audio: "audio/GG-bangardi_MayM.mp3",
          },
        },
        labelFem: {
          W: {
            label: "Ngal-kamarrang",
            audio: "audio/ngalkamarrang.mp3",
          },
          Mg: {
            label: "Ngalamarrang",
          },
          KB: {
            label: "Ngal-kamarrang",
            audio: "audio/ngalkamarrang.mp3",
          },
          Kdj: {
            label: "Al-kamarrang",
            audio: "audio/alkamarrang.mp3",
          },
          I: {
            label: "Bangardidjan",
          },
          E: {
            label: "Bangœrn",
          },
          BG: {
            label: "Bangardijan",
            audio: "audio/UO_bangardijan_01.mp3",
          },
          NDj: {
            label: "Njakamárrang",
          },
          Dj: {
            label: "Bangaditjan",
          },
          Gg: {
            label: "Bangardidjan",
            audio: "audio/GG_bangardidjan_MayM.mp3",
          },
        },
        x: NGARRADJKU_X + INSET,
        y: GRAPH_START_Y + RECT_HEIGHT,
        color: YIRRIDJDJA_COLOR,
        font: font,
        title: "Yirridjdja",
        ...nodeDimensions,
      },
      {
        id: "xbulanj",
        labelMasc: {
          W: {
            label: "Na-bulanj",
            audio: "audio/nabulanj.mp3",
          },
          Mg: {
            label: "Nawurlany",
          },
          KB: {
            label: "Na-bulanj",
            audio: "audio/nabulanj.mp3",
          },
          Kdj: {
            label: "Na-bulanj",
            audio: "audio/nabulanj.mp3",
          },
          I: {
            label: "Kela",
          },
          E: {
            label: "Kela",
          },
          BG: {
            label: "Gela / Burralang",
            audio: "audio/UO_gela_01.mp3",
          },
          NDj: {
            label: "Nbúlanj",
          },
          Dj: {
            label: "Burralang",
          },
          Gg: {
            label: "Gela",
            audio: "audio/GG_gela_LY.mp3",
          },
        },
        labelFem: {
          W: {
            label: "Ngal-bulanj",
            audio: "audio/ngalbulanj.mp3",
          },
          Mg: {
            label: "Ngalwurlany",
          },
          KB: {
            label: "Ngal-bulanj",
            audio: "audio/ngalbulanj.mp3",
          },
          Kdj: {
            label: "Al-bulanj",
            audio: "audio/albulanj.mp3",
          },
          I: {
            label: "Kalidjan",
          },
          E: {
            label: "Kalidjan",
          },
          BG: {
            label: "Galijan",
            audio: "audio/UO_galijan_01.mp3",
          },
          NDj: {
            label: "Njabúlanj",
          },
          Dj: {
            label: "Galidjan",
          },
          Gg: {
            label: "Galidjan",
            audio: "audio/GG_galidjan_MayM.mp3",
          },
        },
        x: NGARRADJKU_X + INSET,
        y: GRAPH_START_Y + RECT_HEIGHT * 2,
        color: DUWA_COLOR,
        font: font,
        title: "Duwa",
        ...nodeDimensions,
      },
      {
        id: "xwamud",
        labelMasc: {
          W: {
            label: "Na-wamud",
            audio: "audio/nawamud.mp3",
          },
          Mg: {
            label: "Nawumut",
          },
          KB: {
            label: "Na-wamud",
            audio: "audio/nawamud.mp3",
          },
          Kdj: {
            label: "Na-wamud",
            audio: "audio/nawamud.mp3",
          },
          I: {
            label: "Kodjok",
            audio: "audio/kodjok.mp3",
          },
          E: {
            label: "Kodjok",
            audio: "audio/kodjok.mp3",
          },
          BG: {
            label: "Gojok",
            audio: "audio/UO_gojok_01.mp3",
          },
          NDj: {
            label: "Wámud",
          },
          Dj: {
            label: "Gutjuk",
          },
          Gg: {
            label: "Godjok",
            audio: "audio/GG_godjok_LY.mp3",
          },
        },
        labelFem: {
          W: {
            label: "Ngal-wamud",
            audio: "audio/ngalwamud.mp3",
          },
          Mg: {
            label: "Ngalawmut",
          },
          KB: {
            label: "Ngal-wamud",
            audio: "audio/ngalwamud.mp3",
          },
          Kdj: {
            label: "Al-wamud",
            audio: "audio/alwamud.mp3",
          },
          I: {
            label: "Kodjdjan",
            audio: "audio/kodjdjan.mp3",
          },
          E: {
            label: "Kodjdjan",
            audio: "audio/kodjdjan.mp3",
          },
          BG: {
            label: "Gochan",
            audio: "audio/UO_gochan_01.mp3",
          },
          NDj: {
            label: "Njáwamud",
          },
          Dj: {
            label: "Gutjan",
          },
          Gg: {
            label: "Gotjan",
            audio: "audio/GG_gotjan_MayM.mp3",
          },
        },
        x: NGARRADJKU_X,
        y: GRAPH_START_Y + RECT_HEIGHT * 3,
        color: YIRRIDJDJA_COLOR,
        font: font,
        title: "Yirridjdja",
        ...nodeDimensions,
      },
      // Mardku
      {
        id: "xwakadj",
        labelMasc: {
          W: {
            label: "Na-wakadj",
            audio: "audio/nawakadj.mp3",
          },
          Mg: {
            label: "Nawagaj / Nawaj",
          },
          KB: {
            label: "Na-wakadj",
            audio: "audio/nawakadj.mp3",
          },
          Kdj: {
            label: "Na-wakadj",
            audio: "audio/nawakadj.mp3",
          },
          I: {
            label: "Ngarridj",
            audio: "audio/ngarridj.mp3",
          },
          E: {
            label: "Ngarridj",
            audio: "audio/ngarridj.mp3",
          },
          BG: {
            label: "Ngarrich",
            audio: "audio/UO_ngarrich_01.mp3",
          },
          NDj: {
            label: "Wákadj",
          },
          Dj: {
            label: "Ngarritj",
          },
          Gg: {
            label: "Ngarridj",
            audio: "audio/GG_ngarridj_LY.mp3",
          },
        },
        labelFem: {
          W: {
            label: "Ngal-wakadj",
            audio: "audio/ngalwakadj.mp3",
          },
          Mg: {
            label: "Ngalawaj",
          },
          KB: {
            label: "Ngal-wakadj",
            audio: "audio/ngalwakadj.mp3",
          },
          Kdj: {
            label: "Al-wakadj",
            audio: "audio/alwakadj.mp3",
          },
          I: {
            label: "Ngarridjdjan",
          },
          E: {
            label: "Ngarridjdjan",
          },
          BG: {
            label: "Ngarrichan",
            audio: "audio/UO_ngarrichan_01.mp3",
          },
          NDj: {
            label: "Njáwakadj",
          },
          Dj: {
            label: "Ngarritjan",
          },
          Gg: {
            label: "Ngarritjan",
            audio: "audio/GG_ngarritjan_LY.mp3",
          },
        },
        x: MARDKU_X,
        y: GRAPH_START_Y,
        color: YIRRIDJDJA_COLOR,
        font: font,
        title: "Yirridjdja",
        ...nodeDimensions,
      },
      {
        id: "xbangardi",
        labelMasc: {
          W: {
            label: "Na-bangardi",
            audio: "audio/nabangardi.mp3",
          },
          Mg: {
            label: "Nawangari",
          },
          KB: {
            label: "Na-bangardi",
            audio: "audio/nabangardi.mp3",
          },
          Kdj: {
            label: "Na-bangardi",
            audio: "audio/nabangardi.mp3",
          },
          I: {
            label: "Kamarrang",
          },
          E: {
            label: "Kamarrang",
          },
          BG: {
            label: "Gamarrang",
            audio: "audio/UO_gamarrang_01.mp3",
          },
          NDj: {
            label: "Nabangárda",
          },
          Dj: {
            label: "Gamarrang",
          },
          Gg: {
            label: "Gamarrang",
            audio: "audio/GG_gamarrang_LY.mp3",
          },
        },
        labelFem: {
          W: {
            label: "Ngal-bangardi",
            audio: "audio/ngalbangardi.mp3",
          },
          Mg: {
            label: "Ngalwangari",
          },
          KB: {
            label: "Ngal-bangardi",
            audio: "audio/ngalbangardi.mp3",
          },
          Kdj: {
            label: "Al-bangardi",
            audio: "audio/albangardi.mp3",
          },
          I: {
            label: "Kamanj",
          },
          E: {
            label: "Kamanj",
          },
          BG: {
            label: "Gamanyjan",
            audio: "audio/UO_gamanyjan_01.mp3",
          },
          NDj: {
            label: "Njabangárda",
          },
          Dj: {
            label: "Gamandjan",
          },
          Gg: {
            label: "Gamanj",
            audio: "audio/GG_gamanj_MayM.mp3",
          },
        },
        x: MARDKU_X - INSET,
        y: GRAPH_START_Y + RECT_HEIGHT,
        color: DUWA_COLOR,
        font: font,
        title: "Duwa",
        ...nodeDimensions,
      },
      {
        id: "xkangila",
        labelMasc: {
          W: {
            label: "Na-kangila",
            audio: "audio/nakangila.mp3",
          },
          Mg: {
            label: "Nangila",
          },
          KB: {
            label: "Na-kangila",
            audio: "audio/nakangila.mp3",
          },
          Kdj: {
            label: "Na-kangila",
            audio: "audio/nakangila.mp3",
          },
          I: {
            label: "Bulanj",
            audio: "audio/bulanj.mp3",
          },
          E: {
            label: "Bulanj",
            audio: "audio/bulanj.mp3",
          },
          BG: {
            label: "Bulany",
            audio: "audio/UO_bulany_01.mp3",
          },
          NDj: {
            label: "Nkangíla",
          },
          Dj: {
            label: "Bulany",
          },
          Gg: {
            label: "Burlanj",
            audio: "audio/GG_burlanj_MayM.mp3",
          },
        },
        labelFem: {
          W: {
            label: "Ngal-kangila",
            audio: "audio/ngalkangila.mp3",
          },
          Mg: {
            label: "Ngalangila",
          },
          KB: {
            label: "Ngal-kangila",
            audio: "audio/ngalkangila.mp3",
          },
          Kdj: {
            label: "Al-kangila",
            audio: "audio/alkangila.mp3",
          },
          I: {
            label: "Bulanjdjan",
          },
          E: {
            label: "Bulanjdjan",
          },
          BG: {
            label: "Bulanyjan",
            audio: "audio/UO_bulanyjan_01.mp3",
          },
          NDj: {
            label: "Njakangíla",
          },
          Dj: {
            label: "Bulanydjan",
          },
          Gg: {
            label: "Burlandjan",
            audio: "audio/GG_burlanjdjan_LY.mp3",
          },
        },
        x: MARDKU_X - INSET,
        y: GRAPH_START_Y + RECT_HEIGHT * 2,
        color: YIRRIDJDJA_COLOR,
        font: font,
        title: "Yirridjdja",
        ...nodeDimensions,
      },
      {
        id: "xkodjok",
        labelMasc: {
          W: {
            label: "Na-kodjok",
            audio: "audio/nakodjok.mp3",
          },
          Mg: {
            label: "Nawuyuk",
          },
          KB: {
            label: "Na-kodjok",
            audio: "audio/nakodjok.mp3",
          },
          Kdj: {
            label: "Na-kodjok",
            audio: "audio/nakodjok.mp3",
          },
          I: {
            label: "Wamud",
            audio: "audio/wamud.mp3",
          },
          E: {
            label: "Wamud",
            audio: "audio/wamud.mp3",
          },
          BG: {
            label: "Wamut",
            audio: "audio/UO_wamut_01.mp3",
          },
          NDj: {
            label: "Nkóyok",
          },
          Dj: {
            label: "Wamut",
          },
          Gg: {
            label: "Wamut",
            audio: "audio/GG_wamut_MayM.mp3",
          },
        },
        labelFem: {
          W: {
            label: "Ngal-kodjok",
            audio: "audio/ngalkodjok.mp3",
          },
          Mg: {
            label: "Ngalwuyuk",
          },
          KB: {
            label: "Ngal-kodjok",
            audio: "audio/ngalkodjok.mp3",
          },
          Kdj: {
            label: "Al-kodjok",
            audio: "audio/alkodjok.mp3",
          },
          I: {
            label: "Wamuddjan",
          },
          E: {
            label: "Wamuddjan",
          },
          BG: {
            label: "Wamutchan",
            audio: "audio/UO_wamutchan_01.mp3",
          },
          NDj: {
            label: "Njakóyok",
          },
          Dj: {
            label: "Wamutjan",
          },
          Gg: {
            label: "Wamutjan",
            audio: "audio/GG_wamutjan_LY.mp3",
          },
        },
        x: MARDKU_X,
        y: GRAPH_START_Y + RECT_HEIGHT * 3,
        color: DUWA_COLOR,
        font: font,
        title: "Duwa",
        ...nodeDimensions,
      },
    ],
    edges: [
      // Mother/child
      //  Ngarradjku
      {
        from: "xngarridj",
        to: "xkamarrang",
        length: RECT_HEIGHT,
        color: MOTHER_CHILD_COLOR,
        title: "mother of",
        id: "xngarridj-mother-of-xkamarrang",
      },
      {
        from: "xkamarrang",
        to: "xbulanj",
        length: RECT_HEIGHT,
        color: MOTHER_CHILD_COLOR,
        title: "mother of",
        id: "xkamarrang-mother-of-xbulanj",
      },
      {
        from: "xbulanj",
        to: "xwamud",
        length: RECT_HEIGHT,
        color: MOTHER_CHILD_COLOR,
        title: "mother of",
        id: "xbulanj-mother-of-xwamud",
      },
      {
        from: "xwamud",
        to: "xngarridj",
        smooth: true,
        color: MOTHER_CHILD_COLOR,
        title: "mother of",
        id: "xwamud-mother-of-xngarridj",
        curvePoint: { x: NGARRADJKU_X - curveWidth, y: graphCenterY },
        arrowDirection: "right",
      },
      // Mardku
      {
        from: "xwakadj",
        to: "xbangardi",
        length: RECT_HEIGHT,
        color: MOTHER_CHILD_COLOR,
        title: "mother of",
        id: "xwakadj-mother-of-xbangardi",
      },
      {
        from: "xbangardi",
        to: "xkangila",
        length: RECT_HEIGHT,
        color: MOTHER_CHILD_COLOR,
        title: "mother of",
        id: "xbangardi-mother-of-xkangila",
      },
      {
        from: "xkangila",
        to: "xkodjok",
        length: RECT_HEIGHT,
        color: MOTHER_CHILD_COLOR,
        title: "mother of",
        id: "xkangila-mother-of-xkodjok",
      },
      {
        from: "xkodjok",
        to: "xwakadj",
        smooth: true,
        color: MOTHER_CHILD_COLOR,
        title: "mother of",
        id: "xkodjok-mother-of-xwakadj",
        curvePoint: { x: MARDKU_X + curveWidth, y: graphCenterY },
        arrowDirection: "left",
      },
      // Marriage choice 1
      // Ngarradjku -> Mardku
      {
        from: "xngarridj",
        to: "xwakadj",
        length: RECT_WIDTH,
        color: MARRIAGE_1_COLOR,
        title: "should marry",
        id: "xngarridj-should-marry-xwakadj",
      },
      {
        from: "xkamarrang",
        to: "xbangardi",
        length: RECT_WIDTH,
        color: MARRIAGE_1_COLOR,
        title: "should marry",
        id: "xkamarrang-should-marry-xbangardi",
      },
      {
        from: "xbulanj",
        to: "xkangila",
        length: RECT_WIDTH,
        color: MARRIAGE_1_COLOR,
        title: "should marry",
        id: "xbulanj-should-marry-xkangila",
      },
      {
        from: "xwamud",
        to: "xkodjok",
        length: RECT_WIDTH,
        color: MARRIAGE_1_COLOR,
        title: "should marry",
        id: "xwamud-should-marry-xkodjok",
      },
      // Mardku -> Ngarradjku
      {
        from: "xwakadj",
        to: "xngarridj",
        length: RECT_WIDTH,
        color: MARRIAGE_1_COLOR,
        title: "should marry",
        id: "xwakadj-should-marry-xngarridj",
      },
      {
        from: "xbangardi",
        to: "xkamarrang",
        length: RECT_WIDTH,
        color: MARRIAGE_1_COLOR,
        title: "should marry",
        id: "xbangardi-should-marry-xkamarrang",
      },
      {
        from: "xkangila",
        to: "xbulanj",
        length: RECT_WIDTH,
        color: MARRIAGE_1_COLOR,
        title: "should marry",
        id: "xkangila-should-marry-xbulanj",
      },
      {
        from: "xkodjok",
        to: "xwamud",
        length: RECT_WIDTH,
        color: MARRIAGE_1_COLOR,
        title: "should marry",
        id: "xkodjok-should-marry-xwamud",
      },

      // Marriage choice 2
      // Ngarradjku -> Mardku
      {
        from: "xngarridj",
        to: "xkangila",

        color: MARRIAGE_2_COLOR,
        title: "should marry",
        id: "xngarridj-should-marry-xkangila",
      },
      {
        from: "xkamarrang",
        to: "xkodjok",

        color: MARRIAGE_2_COLOR,
        title: "should marry",
        id: "xkamarrang-should-marry-xkodjok",
      },
      {
        from: "xbulanj",
        to: "xwakadj",

        color: MARRIAGE_2_COLOR,
        title: "should marry",
        id: "xbulanj-should-marry-xwakadj",
      },
      {
        from: "xwamud",
        to: "xbangardi",

        color: MARRIAGE_2_COLOR,
        title: "should marry",
        id: "xwamud-should-marry-xbangardi",
      },
      // Mardku -> Ngarradjku
      {
        from: "xwakadj",
        to: "xbulanj",

        color: MARRIAGE_2_COLOR,
        title: "should marry",
        id: "xwakadj-should-marry-xbulanj",
      },
      {
        from: "xbangardi",
        to: "xwamud",

        color: MARRIAGE_2_COLOR,
        title: "should marry",
        id: "xbangardi-should-marry-xwamud",
      },
      {
        from: "xkangila",
        to: "xngarridj",

        color: MARRIAGE_2_COLOR,
        title: "should marry",
        id: "xkangila-should-marry-xngarridj",
      },
      {
        from: "xkodjok",
        to: "xkamarrang",

        color: MARRIAGE_2_COLOR,
        title: "should marry",
        id: "xkodjok-should-marry-xkamarrang",
      },
    ],
  };

  const legend = {
    items: [
      {
        color: DUWA_COLOR,
        label: graph.moieties.Duwa.languages[language],
        shape: "circle",
      },
      {
        color: YIRRIDJDJA_COLOR,
        label: graph.moieties.Yirridjdja.languages[language],
        shape: "circle",
      },
      {
        color: MOTHER_CHILD_COLOR,
        label: "Mother → Child",
        shape: "arrow",
      },
      {
        color: MARRIAGE_1_COLOR,
        label: "Potential partner",
        shape: "arrow",
      },
    ],
  };

  const options = {
    width: graphWidth,
    height: graphHeight,
  };

  return (
    <div className="scrollbar-padding">
      <div
        className="App"
        ref={visRef}
        style={{ maxWidth: 800, margin: "0 auto" }}
      >
        {/* Legend */}
        <div
          className="legend-wrapper"
          style={{
            width: fullGraphWidth,
          }}
        >
          {legend.items.map((item, index) => {
            return (
              <div key={index} className="legend-item">
                {item.shape === "circle" && (
                  <LegendCircle height={20} width={20} color={item.color} />
                )}
                {item.shape === "arrow" && (
                  <LegendLine height={20} width={20} color={item.color} />
                )}
                <div style={{ display: "inline-block", marginLeft: 5 }}>
                  {item.label}
                </div>
              </div>
            );
          })}
        </div>
        <div
          style={{
            width: fullGraphWidth,
            margin: "0 auto",
            borderRadius: 5,
            padding: PADDING,
            boxSizing: "border-box",
          }}
        >
          <SkinsGraph
            graph={graph}
            options={options}
            colorMap={colorMap}
            baseFontSize={graphFontSize}
            nodeDimensions={nodeDimensions}
            hideMatriLabels={hideMatriLabels}
            defaultLanguage={defaultLanguage}
            hideExplainerSentences={hideExplainerSentences}
            language={language}
          />
        </div>
        {/* Language option switcher (radio buttons) */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          {languageOptions.map((languageOption) => (
            <label
              key={languageOption}
              style={{
                marginRight: 12,
                marginBottom: 12,
              }}
            >
              <input
                type="radio"
                name="language"
                value={languageOption}
                checked={language === languageOption}
                onChange={(e) => {
                  setLanguage(e.target.value);
                }}
                style={{
                  marginRight: 4,
                }}
              />
              {graph.languages[languageOption]}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
