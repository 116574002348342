export const LegendLine = ({ height, width, color }) => {
  return (
    <div
      style={{
        display: "inline-flex",
        height: height,
        width: width,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: height / 4,
          width: width,
          backgroundColor: color,
        }}
      />
    </div>
  );
};

export const LegendCircle = ({ height, width, color }) => {
  return (
    <div
      style={{
        display: "inline-flex",
        height: height,
        width: width,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: height * 0.75,
          width: width,
          backgroundColor: color,
          borderRadius: "50%",
        }}
      />
    </div>
  );
};
